
/**
 * 解析URL参数
 * @param content 内容
 * @param time    时间
 */
export const scene = (option)=>{
	if((option.scene || null) != null && typeof option.scene != 'number'){
		let scene = decodeURIComponent(option.scene);
		
		let res = {};
		let arr = scene.split("&");
		for (let i = 0; i < arr.length; i++) {
			let param = arr[i].split("=");
			res[param[0].replace(/(^\s*)|(\s*$)/g, "")] = decodeURIComponent(param[1].replace(/(^\s*)|(\s*$)/g, ""));
		}
		
		if(res.spid != undefined){
			uni.$storage.set("spid",res.spid);
		}
		
		return res;
	}
	
	if((option.query || null) != null){
		uni.$storage.set("spid",option.query.spid);
		return option.query;
	}
	
	// #ifdef MP
	// if(option.query.hasOwnProperty('scene')){
	// 	switch(option.scene){
	// 		case 1001: // 直接进入小程序
	// 		case 1011: // 扫码
	// 		case 1012: // 长按图片识别二维码
	// 		case 1013: // 手机相册选取二维码
	// 		case 1047: // 扫描小程序码
	// 		case 1048: // 长按图片识别小程序码
	// 		case 1049: // 手机相册选取小程序码
	// 			let scene = decodeURIComponent(option.query.scene);
	// 			let res = {};
	// 			let arr = scene.split("&");
	// 			for(let i = 0; i < arr.length; i++) {
	// 				let param = arr[i].split("=");
	// 				res[param[0].replace(/(^\s*)|(\s*$)/g, "")] = decodeURIComponent(param[1].replace(/(^\s*)|(\s*$)/g, ""));
	// 			}
	
	// 			if((res.spid != null || res.spid != undefined) && res.spid > 0){
	// 				uni.$storage.set("spid",res.spid);
	// 			}
	
	// 			return res;
	// 	}
	// }
	// #endif
	
	return option;
}