<script setup>
	import { ref,provide } from "vue";
	import { navigateTo } from "@/el-ui/libs/navigate";
	import { scene } from "@/el-ui/libs/promotion.js";
	import { useTabbarStore } from "@/store/tabbar";
	import { useAppStore } from "@/store/app";
	import { useUserStore } from "@/store/user";
	import { onLaunch,onShow,onHide,onError } from "@dcloudio/uni-app";
	
	const { getUser }   = useUserStore();
	const tabbarStore   = useTabbarStore();
	const appStore      = useAppStore();
	
	const updateManager = ()=>{
		const updateManager = uni.getUpdateManager();
		updateManager.onCheckForUpdate(res => {
		  // 请求完新版本信息的回调
		  // console.log(res.hasUpdate)
		});
		
		updateManager.onUpdateReady(() => {
			uni.showModal({
				title: '更新提示',
				content: '新版本已经准备好，即将重启应用',
				showCancel: false,
				success(res) {
					if (res.confirm) {
						// 新的版本已经下载好，调用 applyUpdate 应用新版本并重启
						updateManager.applyUpdate();
					}
				}
			});
		});
		
		updateManager.onUpdateFailed(() => {
			uni.showModal({
				title: '更新提示',
				content: '新版本下载失败',
				showCancel: false
			});
		});
	}
	
	onLaunch(async (option)=>{
		scene(option);
		uni.hideTabBar({
			success: () => {},
			fail: () => {}
		});
		
		// #ifdef MP-WEIXIN
		updateManager();
		// #endif
		
		let params = {};
		// #ifdef H5
		params.temp_id = option.query.temp_id;
		// #endif
		uni.$http.get("common/init",params).then(res=>{
			if(res.code){
				let tabbar = res.data.tabbar;
				appStore.setData({ site: res.data.site, theme: res.data.theme });
				tabbarStore.setData(tabbar);
				if(tabbar.list[0].link != uni.$config.get("tabBarList.defaultHome","")){
					navigateTo(tabbar.list[0].link);
				}
			}
		})
		
		// #ifdef H5
		// 链接推广
		let arr = window.location.href.split("#");
		if(arr[1] != undefined){
			let params = arr[1].split("=");
			if(params.length >= 2 && params[0] == "u"){
				uni.$storage.set("uid",params[1]);
			}
		}else{
			if(option.query.u != undefined && option.query.u > 0){
				uni.$storage.set("uid",option.query.u);
			}
		}
		// #endif
		
		await getUser();
	});
	
	onShow(()=>{
		console.log('App Show')
	});
	
	onHide(()=>{
		console.log('App Hide')
	});
	
	onError((error)=>{
		console.log('app error',error);
	});
	
</script>

<style lang="scss">
	@import '~@/el-ui/scss/style.scss';
	@import '~@/static/fonts/style.css';
	page { background-color: #f8f8f8; color: #303133; }
	uni-checkbox .uni-checkbox-input, uni-checkbox .wx-checkbox-input { border-radius: 50rpx; }
	// #ifdef H5
	uni-toast { z-index: 9000000 !important; }
	uni-modal { z-index: 9000000 !important; }
	// #endif
	::-webkit-scrollbar { display: none; width: 0 !important; height: 0 !important; -webkit-appearance: none; background: transparent; }
</style>
